import { snackbarConstants } from "../constants/constants";

/**
 * Open snackbar
 * @param {*} type - content type
 * @returns
 */
export const openSnackbar = (type) => {
	return { type: snackbarConstants.OPEN, data: type };
};

/**
 * Close snackabr
 * @returns
 */
export const closeSnackbar = () => {
	return { type: snackbarConstants.CLOSE };
};

/**
 * Set initial value for FILES in state
 * @param {*} data - initial value
 * @returns
 */
export const setFilesData = (data) => {
	return { type: snackbarConstants.SET_FILES_DATA, data: data };
};

/**
 * Remove file
 * @param {*} fileId - file to be removed
 * @returns
 */
export const removeFileFromList = (fileId) => {
	return { type: snackbarConstants.REMOVE_FILE, data: fileId };
};

/**
 * Update value for upload file progress
 * @param {*} files - updated value
 * @returns
 */
export const updateUploadProgress = (files) => {
	return { type: snackbarConstants.UPDATE_UPLOAD_PROGRESS, data: files };
};

/**
 * Cancel API request for upload file
 * @param {*} fileId - file to be canceled upload
 * @returns
 */
export const cancelUploadRequest = (fileId) => {
	return { type: snackbarConstants.CANCEL_UPLOAD_REQUEST, data: fileId };
};

/**
 * Remove all files and CANCEL API request
 * @returns
 */
export const removeFiles = () => {
	return { type: snackbarConstants.REMOVE_FILES };
};

/**
 * Update BIMIFY progress - Upload bimify file BIM MODEL view
 * @param {*} data
 * @returns
 */
export const updateBimifyProgress = (data) => {
	return { type: snackbarConstants.UPDATE_BIMIFY_PROGRESS, data };
};

/**
 * Cancel BIMIFY progress
 * @param {*} data
 * @returns
 */
export const cancelBimifyProgress = (data) => {
	return { type: snackbarConstants.CANCEL_BIMIFY_PROGRESS, data };
};

/**
 * Reset BIMIFY progress - initial bimify progress
 * @param {*} data
 * @returns
 */
export const resetBimifyProgress = (data) => {
	return { type: snackbarConstants.RESET_BIMIFY_PROGRESS, data };
};

export const setZipName = (zipName) => {
	return { type: snackbarConstants.SET_ZIP_NAME, data: zipName };
};

export const updateExtractProgressLabelingTool = (data) => {
	return {
		type: snackbarConstants.UPDATE_EXTRACT_PROGRESS_LABELING_TOOL,
		data,
	};
};

export const updateExtractProgress = (data) => {
	return { type: snackbarConstants.UPDATE_EXTRACT_PROGRESS, data };
};
