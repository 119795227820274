import React, { useCallback } from "react";
import CustomStepNavigation from "../../../../../../shared/components/CustomStepper/CustomStepNavigation";
import { useNavigate } from "react-router-dom-v5-compat";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { setTempModelUpdateFiles } from "../../../../../../actions";
import { connect } from "react-redux";
import FooterLayout from "../../../../../layout/content/FooterLayout";
import CustomBadge from "../../../../../../shared/components/CustomBadge/CustomBadge";

const FileUploadFooter = ({ buildingId, previousPage, ...props }) => {
	const { files, setTempModelUpdateFiles } = props;
	const navigate = useNavigate();

	const handleUploadFiles = useCallback(
		(files) => {
			setTempModelUpdateFiles(files);
		},
		[setTempModelUpdateFiles]
	);

	const renderBadgeContent = useCallback(() => {
		if (files && files.size > 0) {
			return (
				<div className="d-flex w-50 align-items-center">
					<CustomBadge
						infobarPlacement={true}
						invisible={false}
						badgeContent={files.size}
						customClasses="files-upload-indicator mr-s bg-g300"
					/>
					{localize(LocKeys.FILES_UPLOADED_SUCCESSFULLY)}
				</div>
			);
		}
		return <></>;
	}, [files]);

	return (
		<FooterLayout wrapperClasses="px-24 d-flex align-items-center">
			{renderBadgeContent()}
			<CustomStepNavigation
				showCancel={true}
				cancelButtonAction={() => navigate(-1)}
				leftButtonLabel={localize(LocKeys.BACK)}
				rightButtonClasses="btn--primary"
				rightButtonLabel={localize(LocKeys.NEXT)}
				showUploadFile={true}
				handleUploadFiles={handleUploadFiles}
				leftButtonAction={() => previousPage()}
			/>
		</FooterLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		files: state.getIn(["modelUpdate", "tempFiles"]),
	};
};

export default connect(mapStateToProps, { setTempModelUpdateFiles })(
	FileUploadFooter
);
