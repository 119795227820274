import React from "react";
import { Breadcrumbs as MUIBreadcrumbs, Link } from "@mui/material";
import { withRouter } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ProjectsIcon from "../../shared/icons/navigation/ProjectsIcon";
import ClientsIcon from "../../shared/icons/navigation/ClientsIcon";
import MyOrganisationIcon from "../../shared/icons/navigation/MyOrganisationIcon";
import { connect } from "react-redux";
import QuotesIcon from "../../shared/icons/navigation/QuotesIcon";
import PlusIcon from "../../shared/icons/misc/PlusIcon";
import InvoicesIcon from "../../shared/icons/navigation/InvoicesIcon";
import JobNavIcon from "../../shared/icons/navigation/JobNavIcon";
import { generalConstants } from "../../constants/constants";
import localize, { LocKeys } from "../../constants/localizations";
import LodSpecificationIcon from "../../shared/icons/navigation/LodSpecificationIcon";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

/**
 * Custom Breadcrumb for all components - topbar
 */
const Breadcrumbs = (props) => {
	const {
		history,

		location: { pathname },
		match: { path },
	} = props;

	const pathnames = pathname.split("/").filter((x) => x);
	const paths = path.split("/").filter((x) => x);

	const renderOptionAdd = (option) => {
		return localize(LocKeys.ADD) + " " + option.toString().toLowerCase();
	};
	const switchName = () => {
		if (pathname) {
			let path = pathname.toString();

			if (path.endsWith("/users/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{renderOptionAdd(localize(LocKeys.NEW_USER))}</span>
					</>
				);
			}
			if (path.endsWith("/customers/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>
							{renderOptionAdd(
								localize(LocKeys.NEW_COMPANY, [LocKeys.CUSTOMER])
							)}
						</span>
					</>
				);
			}
			if (path.endsWith("/clients/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>
							{renderOptionAdd(localize(LocKeys.NEW_COMPANY, [LocKeys.CLIENT]))}
						</span>
					</>
				);
			}
			if (path.endsWith("/offers/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_OFFER)}</span>
					</>
				);
			}
			if (path.endsWith("/invoices/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span> {renderOptionAdd(localize(LocKeys.NEW_INVOICE))} </span>
					</>
				);
			}
			if (path.endsWith("/bim-model-specification/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_LOD_SPECIFICATION)}</span>
					</>
				);
			}
			if (path.endsWith("/jobs/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_JOB)}</span>
					</>
				);
			}
			if (path.endsWith("/buildings/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_BUILDING)}</span>
					</>
				);
			}
			if (path.endsWith("/dashboard/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_PACKAGE)}</span>
					</>
				);
			}
			if (path.endsWith("/manuals/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_MANUAL)}</span>
					</>
				);
			}
			if (path.endsWith("/support/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_TICKET)}</span>
					</>
				);
			}
			if (path.endsWith("/component-types/new")) {
				return (
					<>
						<PlusIcon iconClass="d-flex mr-regular new-icon align-items-center" />
						<span>{localize(LocKeys.NEW_COMPONENT_TYPE)}</span>
					</>
				);
			}
		}
	};

	const switchItemName = (name, isLast, isFirst) => {
		const pathPart = name.toString().charAt(0).toUpperCase() + name.slice(1);

		let classes = isFirst && isLast ? "h3 small" : "color-n300 ";
		const iconClasses = isFirst && isLast ? "fill--n500" : "  fill--n300";

		switch (name) {
			case generalConstants.USERS:
				return pathPart;

			case generalConstants.JOBS:
				return (
					<div className="d-flex align-items-center">
						<JobNavIcon
							iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
						/>
						<div className={classes}>{localize(LocKeys.JOBS)}</div>
					</div>
				);

			case generalConstants.OFFERS:
				return (
					<div className="d-flex align-items-center">
						<QuotesIcon
							iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
						/>
						<div className={classes}>{localize(LocKeys.OFFERS)}</div>
					</div>
				);

			case generalConstants.BUILDINGS:
				return (
					<div className="d-flex align-items-center">
						{isFirst && (
							<ProjectsIcon
								iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
							/>
						)}
						<div className={classes}>{localize(LocKeys.BUILDINGS)}</div>
					</div>
				);

			case generalConstants.CUSTOMERS:
				return (
					<div className="d-flex align-items-center">
						<ClientsIcon
							iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
						/>
						<div className={classes}>{localize(LocKeys.CUSTOMERS)}</div>
					</div>
				);

			case generalConstants.LOD_SPECIFICATION:
				return (
					<div className="d-flex align-items-center">
						<LodSpecificationIcon
							iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
						/>
						<div className={classes}>
							{localize(LocKeys.LOD_SPECIFICATIONS)}
						</div>
					</div>
				);
			case generalConstants.SETTINGS:
				return (
					<div className="d-flex align-items-center">
						<MyOrganisationIcon
							iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
						/>

						<div className={classes}>{localize(LocKeys.SETTINGS)}</div>
					</div>
				);

			case generalConstants.INVOICES:
				if (isFirst) {
					return (
						<div className="d-flex align-items-center">
							<InvoicesIcon
								iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
							/>

							<div className={classes}>{localize(LocKeys.INVOICES)}</div>
						</div>
					);
				}

				return "";

			case generalConstants.SUPPORT:
				if (isFirst) {
					return (
						<div className="d-flex align-items-center">
							<QuestionAnswerOutlinedIcon
								style={{ fontSize: 24, marginRight: 16 }}
							/>
							{/* <TicketingIcon
								iconClass={`d-flex mr-regular icon--24 icon--target-fill ${iconClasses}`}
							/> */}
							<div className={classes}>{localize(LocKeys.SUPPORT)}</div>
						</div>
					);
				}

				return "";

			case generalConstants.DASHBOARD:
				if (isFirst) {
					return (
						<div className="d-flex align-items-center">
							<div className={classes}>{localize(LocKeys.DASHBOARD)}</div>
						</div>
					);
				}

				return "";

			case generalConstants.ADMINISTRATION:
				return (
					<div className="d-flex align-items-center">
						<div className={classes}>{localize(LocKeys.ADMINISTRATION)}</div>
					</div>
				);

			case generalConstants.DELIVERY:
				return localize(LocKeys.BUILDINGS);
			case generalConstants.MANUALS:
				return localize(LocKeys.MANUALS);
			case generalConstants.SYMBOL_CATEGORISATION:
				return localize(LocKeys.SYMBOL_CATEGORISATION);
			case generalConstants.OTHER:
				return localize(LocKeys.OTHER);
			case generalConstants.PRICING:
				return localize(LocKeys.PRICING);
			case generalConstants.LOD_SPECIFICATIONS:
				return localize(LocKeys.LOD_SPECIFICATIONS);
			case generalConstants.LOD:
				return localize(LocKeys.LOD);
			case generalConstants.ENGINEERING_DICIPLINES:
				return localize(LocKeys.ENGINEERING_DICIPLINES);
			case generalConstants.BUILDING_COMPONENTS:
				return localize(LocKeys.BUILDING_COMPONENTS);
			case generalConstants.PARAMETERS:
				return localize(LocKeys.PARAMETERS);
			case generalConstants.LOD_CONTENT:
				return localize(LocKeys.LOD_CONTENT);
			case generalConstants.MODELING:
				return localize(LocKeys.MODELING);
			case generalConstants.ARCHITECTURE:
				return localize(LocKeys.ARCHITECTURE);
			case generalConstants.STRUCTURE:
				return localize(LocKeys.STRUCTURE);
			case generalConstants.MECHANICAL:
				return localize(LocKeys.MECHANICAL);
			case generalConstants.ELECTRICAL:
				return localize(LocKeys.ELECTRICAL);
			case generalConstants.PLUMBING:
				return localize(LocKeys.PLUMBING);
			case generalConstants.LEGAL:
				return localize(LocKeys.LEGAL);
			case generalConstants.SYSTEM_SETTINGS:
				return localize(LocKeys.SYSTEM_SETTINGS);
			case generalConstants.TYPES:
				return localize(LocKeys.BUILDING_TYPES);
			case generalConstants.CATEGORIES:
				return localize(LocKeys.BUILDING_CATEGORIES);
			case generalConstants.DEVELOPMENT_CONSTANTS:
				return localize(LocKeys.DEVELOPMENT_CONSTANTS);
			case generalConstants.DEVELOPMENT_TIME:
				return localize(LocKeys.DEVELOPMENT_TIME);
			case generalConstants.CUSTOMISATION_TIME:
				return localize(LocKeys.CUSTOMISATION_TIME);
			case generalConstants.AUTOMATISATION:
				return localize(LocKeys.AUTOMATISATION);
			case generalConstants.EXPORTS:
				return localize(LocKeys.EXPORTS);
			case generalConstants.SERVICES:
				return localize(LocKeys.SERVICES);
			case generalConstants.SYMBOL:
				return localize(LocKeys.SYMBOLS);
			case generalConstants.COMPONENT_TYPES:
				return localize(LocKeys.COMPONENT_TYPES);
			case generalConstants.CRM_SERVER:
				return localize(LocKeys.CRM_SERVER);
			case generalConstants.DXFP_SERVER:
				return localize(LocKeys.DXFP_SERVER);
			case generalConstants.TEMPLATES:
				return localize(LocKeys.TEMPLATES);
			case generalConstants.REVIT:
				return localize(LocKeys.REVIT_LABEL);
			case generalConstants.TEMPLATE:
				return localize(LocKeys.TEMPLATE_LABEL);
			case generalConstants.ARCHICAD:
				return localize(LocKeys.ARCHICAD_LABEL);
			case generalConstants.ACTIVITY:
				return localize(LocKeys.ACTIVITY);
			case generalConstants.FILES:
				return localize(LocKeys.FILES);
			case generalConstants.MODEL_UPDATE:
				return localize(LocKeys.UPDATE_MODEL);

			case generalConstants.USER_ID:
				return (
					props.user &&
					props.user.get("firstName") + " " + props.user.get("lastName")
				);
			case generalConstants.ORDER_ID:
				return props.orderName && props.orderName;

			case generalConstants.LABELING_TOOL:
				return localize(LocKeys.LABEL);

			case generalConstants.JOB_ID:
				return props.jobName && props.jobName;

			case generalConstants.MANUAL_ID:
				return props.manualName && props.manualName;

			case generalConstants.SYMBOL_ID:
				return props.symbolName && props.symbolName;

			case generalConstants.LOD_CONTENT_ID:
				return props.lodContentName && props.lodContentName;

			case generalConstants.COMPANY_ID:
				return props.companyName && props.companyName;

			case generalConstants.LOD_SPECIFICATION_ID:
				return props.lodSpecificationName && props.lodSpecificationName;

			case generalConstants.BUILDING_ID:
				return props.buildingName && props.buildingName;

			case generalConstants.PACKAGE_ID:
				return props.packageName && props.packageName;

			case generalConstants.LEGAL_ID:
				return localize(LocKeys.OFFER_TERMS_AND_CONDITIONS);

			case generalConstants.TICKET_ID:
				return props.ticketName && props.ticketName;

			case generalConstants.FAMILY_ID:
				return props.familyName && props.familyName;

			case generalConstants.TEMPLATE_ID:
				return props.templateName && props.templateName;

			case generalConstants.TYPE_ID:
				return "";

			case generalConstants.NEW:
				return switchName();

			case generalConstants.EDIT:
				return localize(LocKeys.EDIT);

			default:
				return null;
		}
	};

	return (
		<MUIBreadcrumbs
			separator={<NavigateNextIcon fontSize="small" />}
			aria-label="breadcrumb"
			classes={{
				li: `list-item`,
			}}
		>
			{paths.map((name, index) => {
				let tempName = name;
				let routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
				let isLast = index === paths.length - 1;
				let isSecondToTheLast = index === paths.length - 2;

				if (
					name === generalConstants.SETTINGS &&
					paths[1].startsWith(":view")
				) {
					isLast = true;
				}

				if (
					name.startsWith(":view(bim-model-specifications") ||
					name.startsWith(":second_view") ||
					name.startsWith(":third_view") ||
					name.startsWith(":orderView") ||
					name.startsWith(":historyView")
				) {
					tempName = pathnames.slice(index, index + 1).join("");
				}

				const isFirst = index === 0;

				const itemName = switchItemName(
					tempName,
					isLast,
					isFirst,
					isSecondToTheLast
				);

				if (itemName) {
					return (
						<Link
							classes={{
								root: `link`,
							}}
							underline="none"
							key={itemName}
							onClick={() =>
								history.push({
									pathname: routeTo,
									search: history.location.search,
									state: { isActive: true },
								})
							}
						>
							{itemName}
						</Link>
					);
				}

				return null;
			})}
		</MUIBreadcrumbs>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.getIn(["user", "user"]),
		jobName: state.getIn(["job", "job", "name"]),
		companyName: state.getIn(["company", "company", "name"]),
		orderName: state.getIn(["project", "project", "name"]),
		manualName: state.getIn(["manuals", "manual", "title"]),
		symbolName: state.getIn(["symbol", "symbol", "name"]),
		ticketName: state.getIn(["ticketing", "ticket", "name"]),
		lodContentName: state.getIn([
			"administration",
			"componentCategoryContent",
			"componentCategory",
			"type",
		]),
		packageName: state.getIn(["dashboard", "salesPackage", "name"]),
		buildingName: state.getIn(["building", "building", "name"]),
		templateName: state.getIn(["template", "revitTemplate", "name"]),
		lodSpecificationName: state.getIn([
			"lodSpecification",
			"lodSpecification",
			"name",
		]),
		familyName: state.getIn(["families", "family", "name"]),
	};
};

export default withRouter(connect(mapStateToProps)(Breadcrumbs));
