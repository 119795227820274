import React, { useEffect } from "react";
import { useParams } from "react-router-dom-v5-compat";
import { getJob } from "../../../../actions";
import { connect } from "react-redux";
import BuildingRequestPopups from "../../../buildings/BuildingRequestPopups";
import ActivityHeader from "./parts/ActivityHeader";
import BodyLayout from "../../../layout/content/BodyLayout";
import BuildingInfo from "../../../job/crud/single-view/view/specification-view/BuildingInfo";
import JobInfo from "../../../job/crud/single-view/view/specification-view/JobInfo";
import JobExports from "../../../job/crud/single-view/view/specification-view/JobExports";
import JobAdditionalInfo from "../../../job/crud/single-view/view/specification-view/JobAdditionalInfo";
import JobBIMSpecification from "../../../job/crud/single-view/view/specification-view/JobBIMSpecification";
import FooterLayout from "../../../layout/content/FooterLayout";

const SingleActivity = ({ getJob }) => {
	const { jobId } = useParams();

	useEffect(() => {
		getJob(jobId, true, 1);
	}, [getJob, jobId]);

	return (
		<>
			<BuildingRequestPopups />
			<div className="page-content--large card card--border--none card--2 bg-n000">
				<ActivityHeader />

				<BodyLayout
					wrapperClasses="page-content--medium overflow-y-auto pb-48"
					isTableView={true}
				>
					<BuildingInfo isModelHistory={true} />
					<JobInfo isModelHistory={true} />
					<JobExports isModelHistory={true} />
					<JobAdditionalInfo isModelHistory={true} />
					<JobBIMSpecification />
				</BodyLayout>

				<FooterLayout />
			</div>
		</>
	);
};

export default connect(null, { getJob })(SingleActivity);
