import localize, { LocKeys } from "./localizations";

export const generalConstants = {
	DASHBOARD: "dashboard",
	JOB: "job",
	GRID: "grid",
	LIST: "list",
	ORDER: "order",
	ORDERS: "orders",
	OFFERS: "offers",
	CONVERSATIONS: "conversations",
	LABELING_TOOL: "labeling-tool",
	LOD_SPECIFICATION: "bim-model-specification",
	INVOICES: "invoices",
	JOBS: "jobs",
	USERS: "users",
	QUOTE: "quote",
	SINGLE: "single",
	QUOTES: "quotes",
	BUILDINGS: "buildings",
	BUILDING: "building",
	FILES: "files",
	MANUALS: "manuals",
	EDIT: "edit",
	EMBED: "embed",
	FILE: "file",
	VIEW: "view",
	DRAFT: "draft",
	CREATE: "create",
	ADD: "add",
	PREFILLED: "prefilled",
	PREFILLED_CLIENT: "prefilled-client",
	INVOICE: "invoice",
	NEW: "new",
	SETTINGS: "settings",
	PRIVATE: "private",
	FORBIDDEN: "forbidden",
	MY_ORGANISATION: "my-organisation",
	MY_PROFILE: "my-profile",
	USER_ID: ":userId",
	PROJECT_ID: ":projectId",
	QUOTE_ID: ":quoteId",
	INVOICE_ID: ":invoiceId",
	BUILDING_ID: ":buildingId",
	COMPANY_ID: ":companyId",
	JOB_ID: ":jobId",
	LOD_SPECIFICATION_ID: ":lodSpecificationId",
	MULTI: "multi",
	CALENDAR: "calendar",
	TIME: "time",
	ORDER_ID: ":orderId",
	MANUAL_ID: ":manualId",
	LOD_CONTENT_ID: ":lodContentId",
	PACKAGE_ID: ":packageId",
	CUSTOMERS: "customers",
	ADMINISTRATION: "administration",
	OTHER: "other",
	PRICING: "pricing",
	LOD_SPECIFICATIONS: "bim-model-specifications",
	LOD: "lod",
	ENGINEERING_DICIPLINES: "engineering-disciplines",
	BUILDING_COMPONENTS: "building-components",
	LOD_CONTENT: "lod-content",
	PARAMETERS: "parameters",
	ARCHITECTURE: "architecture",
	MECHANICAL: "mechanical",
	STRUCTURE: "structure",
	ELECTRICAL: "electrical",
	PLUMBING: "plumbing",
	MODELING: "modeling",
	TYPES: "types",
	CATEGORIES: "categories",
	LEGAL: "legal",
	SYSTEM_SETTINGS: "system-settings",
	TYPE_ID: ":typeId",
	DELIVERY: "delivery",
	SYMBOL_CATEGORISATION: "symbol-categorisation",
	SYMBOL_ID: ":symbolId",
	DEVELOPMENT_TIME: "development-time",
	CUSTOMISATION_TIME: "customisation-time",
	AUTOMATISATION_CONSTANTS: "automatisation-constants",
	DEVELOPMENT_CONSTANTS: "development-constants",
	LEGAL_ID: ":legalId",
	TICKETS: "tickets",
	TICKET_ID: ":ticketId",
	SUPPORT: "support",
	AUTOMATISATION: "automatisation",
	EXPORTS: "exports",
	SERVICES: "services",
	FAMILIES: "families",
	SYMBOL: "symbol",
	FAMILY_ID: ":familyId",
	CRM_SERVER: "crm-server",
	DXFP_SERVER: "dxfp-server",
	FAMILY_MAPPING: "family-mapping",
	TEMPLATES: "templates",
	REVIT: "revit",
	TEMPLATE: "template",
	ARCHICAD: "archicad",
	TEMPLATE_ID: ":templateId",
	COMPONENT_TYPES: "component-types",
	ACTIVITY: "activity",
	MODEL_UPDATE: "model-update",
};

export const viewerConstants = {
	FORGE: "forge",
	LIGHTBOX: "lightbox",
};

export const jobStatusConstants = {
	NORMALISATION: "Normalisation",
	CUSTOMISATION: "Customisation",
	QUALITY_ASSURANCE: "Quality assurance",
};

export const bimifyConstants = {
	USER: "USER",
	JOB: "JOB",
	ORDER: "ORDER",
	OFFER: "OFFER",
	CUSTOMER: "CUSTOMER",
	BUILDING: "BUILDING",
	FILES: "FILES",
	LOD_SPECIFICATION: "BIM_MODEL_SPECIFICATION",
	LOD_CONTENT: "LOD_CONTENT",
	MANUALS: "MANUALS",
	FAMILIES: "FAMILIES",
	COMPONENT_TYPES: "COMPONENT_TYPES",
	LEGAL: "LEGAL",
	SYMBOL: "SYMBOL",
	TICKET: "TICKET",
	FAMILY: "FAMILY",
	TEMPLATE: "TEMPLATE",
	ACTIVITY_JOB: "ACTIVITY_JOB",
	ACTIVITY: "ACTIVITY",
};

export const companyTypesConstants = {
	BIMIFY: "bimify",
	RESELLER: "reseller",
	CLIENT: "client",
	PROPERTY_OWNER: "propertyOwner",
	DEVELPER: "developer",
	CONSULATNT: "consultant",
	MODELING_PARTNER: "modeling partner",
	SCANNING_PARTNER: "scanning partner",
};

export const companyTypeLabels = {
	BIMIFY: "Bimify",
	RESELLER: "Reseller",
	CLIENT: localize(LocKeys.CUSTOMER),
	PROPERTY_OWNER: "Property owner",
	DEVELPER: "Developer",
	CONSULATNT: "Consultant",
	MODELING_PARTNER: "Modeling partner",
	SCANNING_PARTNER: "Scanning partner",
};
export const userRolesConstants = {
	ADMIN: "admin",
	BUSINESS_OWNER: "businessOwner",
	PROJECT_LEAD: "projectLead",
	STUDENT: "student",
	GUEST: "guest",
	NORMALIZER: "normalizer",
	CUSTOMIZER: "customizer",
	QUALITY_CONTROL: "qualityControl",
};

export const PRIORITY_USER_ROLE = {
	ADMIN: 1,
	BUSINESS_OWNER: 2,
	PROJECT_LEAD: 3,
	STUDENT: 8,
	GUEST: 7,
	NORMALIZER: 4,
	CUSTOMIZER: 5,
	QUALITY_CONTROL: 6,
};

export const userRoleTypeLabels = {
	admin: "Admin",
	businessOwner: "Business Owner",
	projectLead: "Project Lead",
	student: "Student",
	guest: "Guest",
	normalizer: "Normalizer",
	customizer: "Customizer",
	qualityControl: "Quality Control",
};

export const statusConstants = {
	APPROVE: "approve",
	UNDER_REVIEW: "under_review",
	AWAITING_APPROVAL: "awaiting_approval",
	REJECTED: "rejected",
	DEVELOPMENT: "development",
	IN_DEVELOPMENT: "in_development",
	COMPLETED: "completed",
	TRASHED: "trashed",
	REVIEW: "review",
	DRAFT: "draft",
	PAID: "paid",
	OVERDUE: "overdue",
	ESTIMATE: "estimate",
	PRE_CREATE: "pre_create",
	DECLINED: "declined",
	DONE: "done",
	ONGOING: "ongoing",
	NORMALISATION: "normalisation",
	CUSTOMISATION: "customisation",
	ARCHIVE: "archive",
	ACTIVE: "active",
	REQUESTED: "requested",
	OFFERED: "offered",
	ACCEPTED: "accepted",
	INCOMPLETE: "incomplete",
	ARCHIVED: "archived",
	QA: "qa",
	DELIVERED: "delivered",
	CATEGORISED: "categorised",
	UNCATEGORISED: "uncategorised",
	PENDING: "pending",
	RESOLVED: "resolved",
	REVIEWED: "reviewed",
};

export const dateStatusConstants = {
	COMPLETED: "completedDate",
	UNDER_REVIEW: "underReviewDate",
	AWAITING_APPROVAL: "awaitingApprovalDate",
	REJECTED: "rejectedDate",
	REVIEW: "reviewDate",
	START: "startDate",
	DUE: "dueDate",
};

export const dateStatusLabels = {
	COMPLETED: "Completed Date",
	UNDER_REVIEW: "Under Review Date",
	AWAITING_APPROVAL: "Awaiting Approval Date",
	REJECTED: "Rejected Date",
	REVIEW: "Review Date",
	START: "Start Date",
	DUE: "Due Date",
};

export const buildingStatusConstants = {
	UNDER_REVIEW: "under_review",
	AWAITING_APPROVAL: "awaiting_approval",
	DEVELOPMENT: "development",
	REVIEW: "review",
	COMPLETED: "completed",
	REJECTED: "rejected",
	TRASHED: "trashed",
};

export const statusLabels = {
	REQUEST: "REQUEST",
	APPROVE: "APPROVE",
	UNDER_REVIEW: "UNDER REVIEW",
	AWAITING_APPROVAL: "AWAITING APPROVAL",
	REJECTED: "REJECTED",
	DEVELOPMENT: "DEVELOPMENT",
	IN_DEVELOPMENT: "IN DEVELOPMENT",
	COMPLETED: "COMPLETED",
	TRASHED: "TRASHED",
	REVIEW: "REVIEW",
	DRAFT: "DRAFT",
	ACCEPTED: "ACCEPTED",
	PAID: "PAID",
	PENDING: "PENDING",
	OVERDUE: "OVERDUE",
	CANCELED: "CANCELED",
	ESTIMATE: "PRE-SALES",
};

export const eventTypeMessage = {
	NEW_ORDER_CREATE: localize(LocKeys.NEW_ORDER_CREATE),
	ORDER_CANCELED: localize(LocKeys.ORDER_CANCELED),
	SENT_FOR_APPROVAL: localize(LocKeys.SENT_FOR_APPROVAL),
	ACCEPTED_QUOTE:
		localize(LocKeys.ACCEPTED_LABEL) +
		" " +
		localize(LocKeys.QUOTE).toLowerCase(),
	REJECTED_QUOTE:
		localize(LocKeys.REJECTED_LABEL) +
		" " +
		localize(LocKeys.QUOTE).toLowerCase(),
};

export const eventType = {
	NEW_ORDER_CREATE: "new_project_create",
	ORDER_CANCELED: "canceled_project",
	SEND_FOR_APPROVAL: "send_for_approval",
	ACCEPTED_QUOTE: "accepted_quote",
	REJECTED_QUOTE: "rejected_quote",
};

export const subscriptionTypes = {
	MINI: "mini",
	GO: "go",
	PRO: "pro",
	ENTERPRISE: "enterprise",
	EXCLUSIVE: "exclusive",
};

export const fileTransferConstants = {
	DATA_TRANSFER: "dataTransfer",
};

export const disciplineTypeConstants = {
	ARCHITECTURE: "Architecture",
	STRUCTURAL: "Structural",
	MEP: "MEP",
};

export const sizeConstants = {
	X_LARGE: "x-large",
	LARGE: "large",
	MEDIUM: "medium",
	SMALL: "small",
	SM: "sm",
	M: "m",
};

export const logoTypesConstants = {
	USER: "USER",
	COMPANY: "COMPANY",
};

export const protocolConstants = {
	HTTP: "http",
};

//This constants only use for alert actions, if you need some other status constants like (progress,inprogress...) please create new constants object
export const alertTypeConstants = {
	FAILED: "failed",
	WARNING: "warning",
	INFO: "info",
	ERROR: "error",
	SUCCESS: "success",
};

export const deliveryFormatConstants = {
	REVIT: "Revit",
	ARCHICAD: "ArchiCAD",
	XML: "XML",
};

export const exportsConstants = {
	IFC: "IFC",
	PLAN_VIEWS: "Plan views",
	FACADES: "Facades",
	SECTIONS: "Sections",
};

export const inputFormatConstants = {
	CAD: "CAD",
	IMAGE: "Image",
	POINT_CLOUD: "Point cloud",
};

export const componentCategoryConstants = {
	levelsAndGrids: "levelsAndGrids",
	exteriorWalls: "exteriorWalls",
	interiorWalls: "interiorWalls",
	curtainWalls: "curtainWalls",
	columns: "columns",
	floors: "floors",
	beamsBracesTrusses: "beamsBracesTrusses",
	roofs: "roofs",
	roofAccesssories: "roofAccesssories",
	ceilings: "ceilings",
	stairs: "stairs",
	railings: "railings",
	doors: "doors",
	windows: "windows",
	sunProtectionShuttersBlinds: "sunProtectionShuttersBlinds",
	MEP: "MEP",
	sanitaryEquipment: "sanitaryEquipment",
	appliances: "appliances",
	casework: "casework",
	looseFurniture: "looseFurniture",
	rooms: "rooms",
};

export const responseDeliveryTypeConstants = {
	SNACKBAR: "SNACKBAR",
	MODAL: "MODAL",
};

export const responseContentTypeConstants = {
	DEFAULT: "DEFAULT",
	EMAIL: "EMAIL",
};
export const colorConstants = {
	N000: "#ffff",
	PRIMARY: "#f79400",
	N200: "#c5cdd3",
	N300: "#7f909f",
	N500: "#1b2024",
	SUCCESS: "#48bf92",
	ERROR: "#e93547",
	BLUE: "#3198ed",
	BLUE_LIGHT: "#ecf6fd",
	BLUE_DARK: "#2877b8",
	N150: "#e8ebed",
	N100: "#f4f8fa",
	RED: "#e93547",
	INACTIVE: "#fafcfd",
};
export const fileEntitiesConstants = {
	PROJECT: "project",
	BUILDING: "building",
	CHAT: "chat",
};

export const snackbarConstants = {
	OPEN: "OPEN",
	CLOSE: "CLOSE",
	SET_FILES_DATA: "SET_FILES_DATA",
	ADD_FILES: "ADD_FILES",
	UPDATE_UPLOAD_PROGRESS: "UPDATE_UPLOAD_PROGRESS",
	REMOVE_FILE: "REMOVE_FILE",
	CANCEL_UPLOAD_REQUEST: "CANCEL_UPLOAD_REQUEST",
	REMOVE_FILES: "REMOVE_FILES",
	UPDATE_BIMIFY_PROGRESS: "UPDATE_BIMIFY_PROGRESS",
	RESET_BIMIFY_PROGRESS: "RESET_BIMIFY_PROGRESS",
	CANCEL_BIMIFY_PROGRESS: "CANCEL_BIMIFY_PROGRESS",
	SET_ZIP_NAME: "SET_ZIP_NAME",
	UPDATE_EXTRACT_PROGRESS: "UPDATE_EXTRACT_PROGRESS",
	UPDATE_EXTRACT_PROGRESS_LABELING_TOOL:
		"UPDATE_EXTRACT_PROGRESS_LABELING_TOOL",
};
export const snackbarTypeConstants = {
	FILE_UPLOAD: "file_upload",
	BIMIFY_PROGRESS: "bimify_progress",
	DOWNLOAD_ZIP: "download_zip",
	EXTRACT_PROGRESS: "extract_progress",
};
export const fileStatusConstants = {
	IN_PROGRESS: "in_progress",
	COMPLETED: "completed",
	CANCELED: "canceled",
};

export const fileSizes = {
	GB_40: 40_000_000_000,
	GB_10: 10_000_000_000,
	GB_5: 5_000_000_000,
	GB: 1_000_000_000,
	MB: 1_000_000,
	kB: 1_000,
	MAX_SINGLE_FILE_SIZE: 100_000_000,
};

export const numberTypeConstants = {
	METERS: "METERS",
	PERCENTAGE: "PERCENTAGE",
};

export const adornmentTypeConstants = {
	EDITOR: "editor",
	EMAIL: "email",
	METERS: "meters",
	PERCENTAGE: "percentage",
	MILIMETERS: "milimeters",
	EURO: "euro",
	EURO_METERS: "euro_meters",
	HOURS: "hours",
	HOUR_METERS: "hour_meters",
	SQUARE_METERS: "square_meters",
	SQUARE_METER_HOUR: "square_meter_hour",
	HOUR_SQUARE_METER: "hour_square_meter",
};

export const fieldTypeConstants = {
	EDITOR: "text",
	EMAIL: "email",
	METERS: "number",
	PERCENTAGE: "number",
	TEXT: "text",
	MILIMETERS: "number",
	NUMBER: "number",
	PAGINATION: "pagination",
	TEXTBOX: "textbox",
	CHECKBOX: "checkbox",
};
export const typographyConstants = {
	H3: "h3",
};

export const fileExtensionConstants = {
	DWG: "dwg",
	DXF: "dxf",
	PNG: "png",
	TIF: "tif",
	TIFF: "tiff",
	RVT: "rvt",
	XML: "xml",
	CAD: "cad",
	PDF: "pdf",
	RCS: "rcs",
	RCP: "rcp",
	E57: "e57",
	PTS: "pts",
	XYZ: "xyz",
};

export const statsConstants = {
	SUMMARY: "summary",
	REQUESTED: "requested",
	OFFERD: "offered",
	INCOMPLETE: "incomplete",
	QUALITY_ASSURANCE: "qualityAssurance",
	NORMALISATION: "normalisation",
	CUSTOMISATION: "customisation",
	BIMIFY: "bimify",
	CUSTOMER_SPECIFIC: "customerSpecific",
	TOTAL_SIZE: "totalSize",
	TOTAL_SIZE_SWEDEN: "totalSizeSweden",
	CONSULATNTS: "consultants",
	PROPERTY_OWNERS: "propertyOwners",
	FACILITY_MANAGEMENT: "facilityManagement",
	RECONSTRUCTION: "reconstruction",
	ENVIRONMENTAL: "environmentalAnalysesAndcertification",
	HIGH_PRIO: "highPrio",
	MEDIUM_PRIO: "mediumPrio",
	LOW_PRIO: "lowPrio",
	PENDING: "pending",
	CUSTOMERS: "customers",
};
export const statsValueConstants = {
	customerSpecific: "Customer specific",
	qualityAssurance: "Quality assurance",
	summary: "Summary",
	requested: "Requested",
	offered: "Offered",
	incomplete: "Incomplete",
	normalisation: "Normalisation",
	customisation: "Customisation",
	bimify: "Bimify",
	totalSize: "Total size",
	totalSizeSweden: "Total size - Sweden",
	newBuildings: "New buildings",
	newCustomers: "New customers",
	consultants: "Consultants",
	propertyOwners: "Property owners",
	numberOfPackages: "Number of packages",
	inputFormat: "Input Format",
	outputFormat: "Output Format",
	facilityManagement: "Facility Management",
	customers: localize(LocKeys.TOTAL_CUSTOMERS),
	reconstruction: "Reconstruction",
	environmentalAnalysesAndcertification:
		"Environmental analyses & certifications",
	highPrio: `${localize(LocKeys.HIGH)} ${localize(
		LocKeys.PRIORITY
	)?.toLowerCase()}`,
	mediumPrio: `${localize(LocKeys.MEDIUM)} ${localize(
		LocKeys.PRIORITY
	)?.toLowerCase()}`,
	lowPrio: `${localize(LocKeys.LOW)} ${localize(
		LocKeys.PRIORITY
	)?.toLowerCase()}`,
	pending: localize(LocKeys.PENDING),
};

export const buildingTypeConstants = {
	COMMERCIAL: "COMMERCIAL",
	OTHER: "OTHER",
	HEALTHCARE: "HEALTHCARE",
	TRANSPORT: "TRANSPORT",
	PUBLIC: "PUBLIC",
	RESIDENTAL: "RESIDENTAL",
};

export const emailActionConstants = {
	ACCEPT_OFFER: "accept-offer",
	DECLINE_OFFER: "decline-offer",
};
