import { reset } from "redux-form";
import { api } from "../api";
import localize, { LocKeys } from "../constants/localizations";
import { templateConstants } from "../constants/templateConstants";
import { getItemFromLocalStorage, getToken } from "../utils/local-storage.util";
import { statusConstants } from "../constants/constants";
import { getPagination, getTemplateFilters } from "../utils/table-filter";
import { clearRevitTemplateQueryParameter } from "./queryParameterActions";

//------------------------------------REVIT TEMPLATE-----------------------------------------

/**
 * GET All Revit Templates endpoint
 *
 * @param {number} page - Current page number
 * @param {string} searchQuery - Search query
 * @param {object} searchFilters - Search filters object (statuses, companyIds)
 * @param {number} limit - Number of items to display per page
 *
 */
export const getRevitTemplates = ({
	page = 1,
	searchQuery = "",
	searchFilters,
	limit = 8,
	usePagination = true,
	customer = getItemFromLocalStorage("Customer"),
	resellerCompanyId = getItemFromLocalStorage("Reseller"),
}) => {
	return (dispatch, getState) => {
		const state = getState();

		const revitTemplateQueryParameter = state.getIn([
			"queryParameter",
			"revitTemplateQueryParameter",
		]);
		const tablePagination = state.getIn(["form", "tablePagination", "values"]);

		const { statuses, companyIds, search } = getTemplateFilters({
			queryParameter: revitTemplateQueryParameter,
			searchFilters,
			searchQuery,
		});
		const { currentPage, currentLimit } = getPagination({
			tablePagination,
			page,
			limit,
		});

		const pagePath = currentPage ? `page=${currentPage}&` : ``;
		const limitPath = currentLimit ? `limit=${currentLimit}&` : ``;
		const searchPath = search ? `search=${search}&` : ``;
		const statusesPath = statuses
			? `statuses=${JSON.stringify(statuses)}&`
			: ``;
		const companyIdsPath = companyIds
			? `companyIds=${JSON.stringify(companyIds)}`
			: ``;

		const paginationPath = `usePagination=${usePagination}&`;

		let queryPath = "";
		if (!usePagination) {
			queryPath = `selectedCompany=${customer}&resellerCompanyId=${resellerCompanyId}&${paginationPath}`;
		} else {
			queryPath = `selectedCompany=${customer}&resellerCompanyId=${resellerCompanyId}&${paginationPath}${pagePath}${limitPath}${searchPath}${statusesPath}${companyIdsPath}`;
		}

		const requestOptions = {
			method: "GET",
			url: `/revit-file?${queryPath}`,
			headers: {
				Authorization: "Bearer " + getToken(),
				"Content-Type": "application/json",
			},
		};
		return api(requestOptions).then(
			(res) => {
				let data = res.data;

				dispatch({ type: templateConstants.GET_REVIT_TEMPLATES, data });
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.message));
			}
		);
	};
};

/**
 * CREATE Revit Template endpoint
 *
 * @param {Object} data - (name, companyId, isDefault ) Contains all form data in a key-value pair
 * @param {Function} cb - Callback function to be executed after the request is successful
 *
 * @returns The result of the request inside the callback function
 */
export const createRevitTemplate = (data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/revit-file`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				const result = res.data.result;

				cb(result);
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE Revit Template endpoint
 *
 * @param {number} id - Unique revit template identifier
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 * @returns
 */
export const updateRevitTemplate = (id, data, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/revit-file/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				cb();
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.result));
			}
		);
	};
};

/**
 * DELETE Revit Template endpoint
 *
 * @param {number[]} ids - Unique revit template identifiers
 * @param {boolean} isSingleView - Boolean value to determine if the request is from a single view
 * @param {Function} cb - Callback function
 * @returns
 */
export const deleteRevitTemplate = (
	ids,
	isSingleView = false,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/revit-file-delete-multiple`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data: { ids },
		};
		return api(requestOptions).then(
			(res) => {
				!isSingleView && dispatch(getRevitTemplates({}));
				dispatch(
					templateActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.TEMPLATE_LABEL)])
					)
				);
				cb();
			},
			(err) => {
				dispatch(
					templateActionGetFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.TEMPLATE_LABEL),
						])
					)
				);
			}
		);
	};
};

/**
 * UPDATE Revit Template Status endpoint
 *
 * @param {number[]} ids - Unique revit template identifiers
 * @param {string} status - Status to update the revit template
 * @param {boolean} isSingleView - Boolean value to determine if the request is from a single view
 * @returns
 */
export const updateTemplateStatus = (ids, status, isSingleView = false) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/revit-file-status-multiple`,
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			data: { status, ids },
		};
		return api(requestOptions).then(
			() => {
				switch (status) {
					case statusConstants.ARCHIVED:
						dispatch(
							templateActionSuccess(
								localize(LocKeys.ITEM_ARCHIVE_SUCCESS, [
									localize(LocKeys.TEMPLATE_LABEL),
								])
							)
						);
						break;
					default:
						dispatch(
							templateActionSuccess(
								localize(LocKeys.ITEM_ACTIVE_SUCCESS, [
									localize(LocKeys.TEMPLATE_LABEL),
								])
							)
						);
						break;
				}

				if (isSingleView) {
					dispatch(getRevitTemplate(ids[0]));
				} else {
					dispatch(getRevitTemplates({}));
				}
			},
			(err) => dispatch(templateActionGetFailure(err.response.data.message))
		);
	};
};

/**
 * GET Single Revit Template
 */
export const getRevitTemplate = (id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/revit-file/${id}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				const result = res.data.result;

				dispatch({
					type: templateConstants.GET_REVIT_TEMPLATE,
					data: result,
				});
				dispatch(setTempRevitTemplate(result));
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.result));
			}
		);
	};
};

/**
 * GET Revit Template Families by discipline component category
 *
 * @param {number} disciplineComponentCategoryId - Unique discipline component category identifier
 * @param {number} disciplineId - Unique discipline identifier
 * @param {number} componentId - Unique component identifier
 * @param {number} templateId - Unique revit template identifier
 */
export const getRevitTemplateFamilies = ({
	disciplineComponentCategoryId,
	disciplineId,
	componentId,
	templateId,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "GET",
			url: `/family-mapping?disciplineComponentCategoryId=${disciplineComponentCategoryId}&revitFileId=${templateId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				dispatch({
					type: templateConstants.GET_REVIT_TEMPLATE_FAMILIES,
					data: { result: res.data?.result, disciplineId, componentId },
				});
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.result));
			}
		);
	};
};

/**
 * GET Revit Template Family Mapping by discipline component category
 *
 * @param {number} disciplineComponentCategoryId - Unique discipline component category identifier
 * @param {number} disciplineId - Unique discipline identifier
 * @param {number} componentId - Unique component identifier
 * @param {number} templateId - Unique revit template identifier
 * @param {Function} cb - Callback function
 *
 */
export const getRevitTemplateFamilyMapping = ({
	disciplineComponentCategoryId,
	disciplineId,
	componentId,
	templateId,
	cb = () => {},
}) => {
	return (dispatch) => {
		const disciplineComponentPath = disciplineComponentCategoryId
			? `&disciplineComponentCategoryId=${disciplineComponentCategoryId}`
			: "";
		const templatePath = templateId ? `&revitFileId=${templateId}` : "";

		const query = `${disciplineComponentPath}${templatePath}`;
		const requestOptions = {
			method: "GET",
			url: `/family?usePagination=false${query}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				const result = res.data?.result;
				dispatch({
					type: templateConstants.GET_REVIT_TEMPLATE_FAMILY_MAPPING,
					data: { result, disciplineId, componentId },
				});
				dispatch(
					setTempRevitTemplateFamilyMapping({
						result,
						disciplineId,
						componentId,
					})
				);
				cb();
			},
			(err) => {
				dispatch(templateActionGetFailure(err.response.data.result));
			}
		);
	};
};

/**
 * UPDATE Revit Template Family Mapping
 * @param {object} data - Contains all form data in a key-value pair
 * @param {Function} cb - Callback function
 * @param {number} disciplineComponentCategoryId - Unique discipline component category identifier
 * @param {number} disciplineId - Unique discipline identifier
 * @param {number} componentId - Unique component identifier
 * @param {number} templateId - Unique revit template identifier
 *
 */
export const updateRevitTemplateFamilyMapping = ({
	data,
	cb = () => {},
	disciplineComponentCategoryId,
	disciplineId,
	componentId,
	templateId,
}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			url: `/family-edit-multiple`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
			data,
		};
		return api(requestOptions).then(
			(res) => {
				dispatch(
					getRevitTemplateFamilyMapping({
						disciplineComponentCategoryId,
						disciplineId,
						componentId,
						templateId,
					})
				);
				dispatch(
					templateActionSuccess(
						localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.FAMILY_MAPPING)])
					)
				);
				cb();
			},
			(err) => {
				dispatch(
					templateActionGetFailure(
						localize(LocKeys.ERROR_ITEM_UPDATED, [
							localize(LocKeys.FAMILY_MAPPING),
						])
					)
				);
			}
		);
	};
};

/**
 * DELETE Revit - Family Mapping
 *
 * @param {number} id - Unique family mapping identifier
 * @param {number} templateId - Unique revit template identifier
 * @param {Function} cb - Callback function
 * @returns
 */
export const deleteRevitTemplateFamilyMapping = (
	id,
	templateId,
	cb = () => {}
) => {
	return (dispatch) => {
		const requestOptions = {
			method: "DELETE",
			url: `/family-mapping?familyId=${id}&revitFileId=${templateId}`,
			headers: {
				Authorization: "Bearer " + getToken(),
			},
		};
		return api(requestOptions).then(
			(res) => {
				cb();
				dispatch(
					templateActionSuccess(
						localize(LocKeys.ITEM_DELETED, [localize(LocKeys.FAMILY_MAPPING)])
					)
				);
			},
			(err) => {
				dispatch(
					templateActionGetFailure(
						localize(LocKeys.ERROR_ITEM_DELETED, [
							localize(LocKeys.FAMILY_MAPPING),
						])
					)
				);
			}
		);
	};
};

//---------------------------TEMP REVIT TEMPLATE-------------------------------

/**
 * Update Revit Template success message
 */
export const updateRevitTemplateMessage = (id, cb = () => {}) => {
	return (dispatch) => {
		dispatch(
			templateActionSuccess(
				localize(LocKeys.ITEM_UPDATED, [localize(LocKeys.TEMPLATE_LABEL)])
			)
		);
		dispatch(getRevitTemplate(id));
		cb();
	};
};

/**
 * Clear Revit Templates
 *
 * @param {Function} cb - Callback function
 */
export const clearRevitTemplates = (cb = () => {}) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.CLEAR_REVIT_TEMPLATES,
		});
		dispatch(clearRevitTemplateQueryParameter());
		cb();
	};
};

/**
 * Create Revit Template success message
 */
export const createRevitTemplateMessage = (cb = () => {}) => {
	return (dispatch) => {
		dispatch(
			templateActionSuccess(
				localize(LocKeys.ITEM_CREATED, [localize(LocKeys.TEMPLATE_LABEL)])
			)
		);
		cb();
	};
};

export const resetTempRevitTemplate = () => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.RESET_TEMP_REVIT_TEMPLATE,
		});
	};
};

export const clearTempRevitTemplate = (formName = null) => {
	return (dispatch) => {
		formName && dispatch(reset(formName));
		dispatch({
			type: templateConstants.CLEAR_TEMP_REVIT_TEMPLATE,
		});
	};
};

export const clearSingleRevitTemplate = () => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.CLEAR_SINGLE_REVIT_TEMPLATE,
		});
	};
};

export const updateTempRevitTemplateData = ({ fieldKey, value }) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.UPDATE_TEMP_REVIT_TEMPLATE_DATA,
			data: { fieldKey, value },
		});
	};
};

export const setTempRevitTemplate = (data) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.SET_TEMP_REVIT_TEMPLATE,
			data,
		});
	};
};

/**
 * SET TEMP revit template file
 * @param {object} data
 * @returns
 */
export const setTempRevitTemplateFile = (data) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.SET_TEMP_REVIT_TEMPLATE_FILE,
			data: data,
		});
	};
};

/**
 * SET TEMP revit template file data
 * @param {object} data
 * @param {string} data.url - url of the file
 * @param {object} data.file - file object
 */
export const setTempRevitTemplateFileData = ({ url, file }) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.SET_TEMP_REVIT_TEMPLATE_FILE_DATA,
			data: { url, file },
		});
	};
};

/**
 * REMOVE TEMP revit template file
 */
export const removeTempRevitTemplateFile = () => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.REMOVE_TEMP_REVIT_TEMPLATE_FILE,
		});
	};
};

/**
 * SET TEMP revit template family mapping
 * @param {object} data
 * @param {number} data.disciplineId - unique discipline identifier
 * @param {number} data.componentId - unique component identifier
 * @param {object} data.result - object containing the revit template family mapping
 */
export const setTempRevitTemplateFamilyMapping = ({
	disciplineId,
	componentId,
	result,
}) => {
	return (dispatch, getState) => {
		const state = getState();
		const stateLods = state.getIn(["administration", "lods"]);

		dispatch({
			type: templateConstants.SET_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING,
			data: { disciplineId, componentId, stateLods, result },
		});
	};
};

/**
 * ADD TEMP revit template family mapping
 * @param {object} data
 * @param {number} data.disciplineId - unique discipline identifier
 * @param {number} data.componentId - unique component identifier
 */
export const addTempRevitTemplateFamilyMapping = ({
	disciplineId,
	componentId,
}) => {
	return (dispatch, getState) => {
		const state = getState();
		const stateLods = state.getIn(["administration", "lods"]);

		dispatch({
			type: templateConstants.ADD_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING,
			data: { disciplineId, componentId, stateLods },
		});
	};
};

/**
 * UPDATE TEMP revit template family mapping
 * @param {object} data
 * @param {number} data.disciplineId - unique discipline identifier
 * @param {number} data.componentId - unique component identifier
 * @param {number} data.tempId - unique temp identifier
 * @param {string} data.value - value
 * @param {string} data.fieldKey - field key to update
 * @param {number} data.lodId - unique lod id identifier
 */
export const updateTempRevitTemplateFamilyMapping = ({
	disciplineId,
	componentId,
	tempId,
	value,
	fieldKey,
	lodId = null,
}) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.UPDATE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING,
			data: { disciplineId, componentId, tempId, value, fieldKey, lodId },
		});
	};
};

export const removeTempRevitTemplateFamilyMapping = ({
	tempId,
	disciplineId,
	componentId,
}) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.REMOVE_TEMP_REVIT_TEMPLATE_FAMILY_MAPPING,
			data: { tempId, disciplineId, componentId },
		});
	};
};

export const updateAllFamilyMappingCheckDefault = ({
	disciplineId,
	componentId,
	value,
	cb,
}) => {
	return (dispatch) => {
		dispatch({
			type: templateConstants.UPDATE_ALL_FAMILY_MAPPING_CHECK_DEFAULT,
			data: { disciplineId, componentId, value },
		});

		cb();
	};
};

//---------------------------------STATE FUNCTION-------------------------------------
/**
 * Snackbar Action Success
 * @param {string} message
 * @returns
 */
const templateActionSuccess = (message) => {
	return {
		type: templateConstants.TEMPLATE_ACTION_SUCCESS,
		data: message,
	};
};

export const templateActionGetFailure = (message) => {
	return {
		type: templateConstants.TEMPLATE_ACTION_FAILURE,
		data: message,
	};
};

export const clearTemplateRequestState = () => {
	return {
		type: templateConstants.CLEAR_TEMPLATE_REQUEST_STATE,
	};
};
