import { AutomateLabeling } from "@bimify-app/labeling-tool";
import React, { useCallback } from "react";
import { baseURL } from "../../api";
import { getToken } from "../../utils/local-storage.util";
import { connect } from "react-redux";
import { updateExtractProgressLabelingTool } from "../../actions";

const AutomateLabelingTool = ({ updateExtractProgressLabelingTool }) => {
	const updateProgress = useCallback(
		(message, action) => {
			updateExtractProgressLabelingTool({ message, action });
		},
		[updateExtractProgressLabelingTool]
	);

	return (
		<AutomateLabeling
			baseURL={baseURL}
			socketURL={window.__ENV__.REACT_APP_API}
			accessToken={getToken()}
			updateProgress={updateProgress}
		/>
	);
};

export default connect(null, { updateExtractProgressLabelingTool })(
	AutomateLabelingTool
);
