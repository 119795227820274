import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
	closeSnackbar,
	getFiles,
	getJob,
	updateExtractProgress,
} from "../../../../actions";
import { snackbarTypeConstants } from "../../../../constants/constants";
import { getSocket } from "../../../../utils/web-socket";
import localize, { LocKeys } from "../../../../constants/localizations";

const ExtractProgress = (props) => {
	const {
		//state
		buildingId,
		jobId,
		disciplineId,
		tempProgress,
		totalLevels,
		buildingLevels,
		//actions
		getJob,
		closeSnackbar,
		updateExtractProgress,
		getFiles,
	} = props;

	useEffect(() => {
		const socket = getSocket();
		socket.connect();
		const event = `job-status-read-pc-${jobId}`;

		const listener = (statusData) => {
			const {
				id: jobDisciplineBuildingLevelFileId,
				percentage,
				message,
				isIpServer = false,
			} = statusData;

			console.log({ statusData });

			let data = {
				progress: percentage,
				message,
				inProgress: true,
				jobId,
				disciplineId,
				buildingId,
				errorResponse: false,
				successResponse: false,
				buildingLevels,
				tempProgress,
				totalLevels,
			};

			// if (!!status && status === "Error") {
			// 	data.open = false;
			// 	data.type = null;
			// 	data.inProgress = false;
			// 	data.errorResponse = true;
			// 	closeSnackbar(snackbarTypeConstants.BIMIFY_PROGRESS);
			// }

			if (isIpServer) {
				//-----------------------------
				const findLevel = buildingLevels.find(
					(level) => +level.id === +jobDisciplineBuildingLevelFileId
				);
				if (findLevel) {
					data.message = `${findLevel.level} - ${message}`;
				}
				data.progress = tempProgress + +percentage / (+totalLevels - 1);

				if (+data.progress >= 90) {
					data.progress = 90;
				}

				if (+percentage === 100) {
					const tempLevels = buildingLevels.filter(
						(level) => +level.id !== +jobDisciplineBuildingLevelFileId
					);
					if (tempLevels.length === 1) {
						data.progress = 90;
						data.tempProgress = 0;

						closeSnackbar(snackbarTypeConstants.EXTRACT_PROGRESS);
					} else {
						data.tempProgress = data.progress;
						data.buildingLevels = tempLevels;
					}
				}
				console.log({ data });
			} else {
				if (+percentage === 100) {
					data.progress = 100;
					data.message = localize(
						LocKeys.EXTRACTION_COMPLETED_WAITING_FOR_SERVER
					);

					// closeSnackbar(snackbarTypeConstants.EXTRACT_PROGRESS);
					getJob(jobId, true, 2);
					getFiles({
						buildingId,
						disciplineId,
						usePagination: false,
						showNormalised: true,
					});
				}
			}

			updateExtractProgress(data);
		};
		socket.on(event, listener);

		return () => {
			socket.off(event, listener);
			socket.disconnect();
		};
	}, [
		totalLevels,
		tempProgress,
		buildingLevels,
		buildingId,
		jobId,
		disciplineId,
		getFiles,
		getJob,
		closeSnackbar,
		updateExtractProgress,
	]);

	return <div></div>;
};
const mapStateToProps = (state) => {
	return {
		progress: state.getIn(["globalWidget", "extractProgress", "progress"]),
		jobId: state.getIn(["globalWidget", "extractProgress", "jobId"]),
		buildingLevels: state.getIn([
			"globalWidget",
			"extractProgress",
			"buildingLevels",
		]),
		disciplineId: state.getIn([
			"globalWidget",
			"extractProgress",
			"disciplineId",
		]),
		tempProgress: state.getIn([
			"globalWidget",
			"extractProgress",
			"tempProgress",
		]),
		totalLevels: state.getIn([
			"globalWidget",
			"extractProgress",
			"totalLevels",
		]),
		buildingId: state.getIn(["globalWidget", "extractProgress", "buildingId"]),
		message: state.getIn(["globalWidget", "extractProgress", "message"]),
	};
};

export default connect(mapStateToProps, {
	getJob,
	updateExtractProgress,
	closeSnackbar,
	getFiles,
})(ExtractProgress);
