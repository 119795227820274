import { jwtDecode } from "jwt-decode";
import authConstants from "../constants/authConstants";
import history from "../history";
import { api } from "../api";
import { SubmissionError } from "redux-form";
import {
	getItemFromLocalStorage,
	getToken,
	removeItemFromLocalStorage,
	setItemToLocalStorage,
	tokenParams,
} from "../utils/local-storage.util";
import {
	isBimifyReseller,
	isLoggedUserCustomizer,
	isLoggedUserGuest,
	isLoggedUserNormalizer,
	isLoggedUserQA,
	isReseller,
} from "../utils/permission/user";
import { setCustomer } from "./globalActions";
import userConstants from "../constants/userConstants";

/**
 * Client-scope function, used to set the path to redirect after login
 *
 * SUCCESS: Populates 'redirectToPath' in the authentication state
 *
 * @param {string} path - path to which the user should be redirected to on success
 */
export const redirectToPath = (path) => {
	return {
		type: authConstants.REDIRECT_TO_PATH,
		path,
	};
};

/**
 * Method used for user authentication
 *
 * SUCCESS:
 *    1. Sets user token to Session storage
 *    2. Triggers loginSuccess() with the retrieved token
 * 	  3. !isBimify --> Set global customer id
 * 	  3. !!getItemFromLocalStorage("redirectToPath") ? redirect user to path `redirectToPath` and remove path form local storage : redirect to other path
 *
 * @param {formvalues} formValues - contains user email & password form value pair
 * @returns - http response with the user token on success
 */
export const login = (formValues, cb = () => {}) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/login`,
			data: JSON.stringify(formValues),
		};
		return api(requestOptions).then(
			(res) => {
				const user = res.data.result?.user || false;
				const isTwoFaCodeEnabled = res.data.result?.isTwoFaCodeEnabled || false;

				if (user && !isTwoFaCodeEnabled) {
					dispatch(loginUser(user));
				}

				if (isTwoFaCodeEnabled) {
					dispatch({
						type: userConstants.SET_TEMP_USER,
						data: { email: formValues?.email },
					});
					cb();
				}
			},
			(err) => {
				throw new SubmissionError({
					email: err.response.data.message,
					password: err.response.data.message,
				});
			}
		);
	};
};

/**
 * Method for user logout
 *
 * SUCCESS:
 *    1. Removes user DATA from local storage
 *    2. Redirects user to /login route
 *    3. Clears state
 *
 */
export const logout = () => {
	return (dispatch) => {
		removeItemFromLocalStorage("Session");
		removeItemFromLocalStorage("Permissions");
		removeItemFromLocalStorage("redirectToPath");
		removeItemFromLocalStorage("Customer");
		removeItemFromLocalStorage("bimifyProgress");
		removeItemFromLocalStorage("extractProgress");
		history.push("/login");
		dispatch({ type: "USER_LOGGED_OUT" });
		dispatch(loginFailure("You've been logged out!"));
	};
};

/**
 * Used to set user password for registration & forgot password actions
 *
 * SUCCESS:
 *    1. Updates Session in local storage with the new token
 *    2. Triggers loginSuccess() function with the new token
 *    3. Redirects to home, "/"
 *
 * @param {formvalues} formValues - contains 'rePassword' & 'reConfirmPassword' values
 * @param {string} email
 * @param {string} token
 * @returns
 */

export const setPassword = (data, email, token) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/set-password?email=${email}&setpasswordtoken=${token}`,
			data,
		};

		return api(requestOptions).then(
			(res) => {
				const token = res.data.result.user.accessToken?.toString();
				const permissions = res.data.result.user.permissions;

				setItemToLocalStorage("Session", token);
				setItemToLocalStorage(
					"Permissions",
					permissions ? JSON.stringify(permissions) : null
				);

				dispatch(loginSuccess(jwtDecode(token)));
				dispatch(setPermissions(permissions));

				let user = jwtDecode(token);

				if (isReseller()) {
					dispatch(setCustomer(user?.company.id, true));
				}

				if (!isBimifyReseller()) {
					dispatch(setCustomer(user?.company.id));
				}

				history.push("/");
			},
			(err) => {
				dispatch(
					actionFailure(err.response.data.message || err.response.data.result)
				);
			}
		);
	};
};

/**
 * Used to trigger a confirmation email for password change to the input email,
 * gets response in terms of success or error - if user does not exist in database
 *
 * SUCCESS: Triggers resetPasswordSuccess() with the request user email
 *
 * @param {formvalues} formValues - contains user email
 */
export const resetPassword = (formValues) => {
	return (dispatch) => {
		const requestOptions = {
			method: "POST",
			url: `/user/reset-password`,
			data: JSON.stringify(formValues),
		};
		return api(requestOptions).then(
			(res) => {
				const email = formValues?.email;
				dispatch(resetPasswordSuccess(email));
			},
			(err) => {
				throw new SubmissionError({
					email: err.response.data.message,
				});
			}
		);
	};
};

/**
 * Method used to request a refreshed token for the user with the requested id
 *
 * SUCCESS:
 *    1. Updates Session in local storage with the new token
 *    2.  Populates 'user' in the authentication state
 *
 * @param {number} id - unique user identifier
 * @returns - http response that contains the updated token & user object
 */
export const refreshToken = (id = tokenParams().id) => {
	return (dispatch) => {
		const requestOptions = {
			method: "PUT",
			headers: {
				Accept: "*/*",
				Authorization: "Bearer " + getToken(),
			},
			url: `/user/${id}/refresh-token`,
		};
		return api(requestOptions).then(
			(res) => {
				const token = res.data.result.user.accessToken;
				const permissions = res.data.result.user.permissions;

				setItemToLocalStorage("Session", token);
				setItemToLocalStorage(
					"Permissions",
					permissions ? JSON.stringify(permissions) : null
				);

				let user = jwtDecode(token);

				dispatch({
					type: authConstants.SET_USER_AUTH,
					data: { user, permissions },
				});
			},
			(err) => {
				dispatch(refreshTokenFailure(err.response.data.message));
			}
		);
	};
};

/**
 * Used this function after user SUCCESS LOGIN for default route, decode token, set data to local storage etc.
 * @param {object} data  - login user object
 * @returns
 */
export const loginUser = (data) => {
	return (dispatch) => {
		const token = data.accessToken?.toString();
		const permissions = data.permissions || null;

		setItemToLocalStorage("Session", token);
		setItemToLocalStorage(
			"Permissions",
			permissions ? JSON.stringify(permissions) : null
		);

		let user = jwtDecode(token);

		dispatch(loginSuccess(jwtDecode(token)));
		dispatch(setPermissions(permissions));

		dispatch({
			type: authConstants.SET_USER_AUTH,
			data: { user, permissions },
		});

		if (isReseller()) {
			dispatch(setCustomer(user?.company.id, true));
		}
		if (!isBimifyReseller()) {
			dispatch(setCustomer(user?.company.id));
		}
		if (!!getItemFromLocalStorage("redirectToPath")) {
			const redirectToPath = JSON.parse(
				getItemFromLocalStorage("redirectToPath")
			);
			const path = redirectToPath.pathname + redirectToPath.search;

			history.push(path);

			removeItemFromLocalStorage("redirectToPath");
		} else {
			if (
				isLoggedUserQA() ||
				isLoggedUserCustomizer() ||
				isLoggedUserNormalizer()
			) {
				history.push("/jobs");
			} else if (isLoggedUserGuest()) {
				history.push("/buildings");
			} else {
				history.push("/dashboard");
			}
		}
	};
};

/**
 * Client-scope function, used to set requestee email to state.
 *
 * SUCCESS:  Populates 'resetPasswordEmail' and 'resetPasswordSuccess' in the authentication state
 *
 * @param {string} email
 */
export const resetPasswordSuccess = (email) => {
	return {
		type: authConstants.RESET_PASSWORD_SUCCESS,
		email,
	};
};

/**
 * Clear reset password
 * @returns
 */
export const clearResetPassword = () => {
	return {
		type: authConstants.CLEAR_RESET_PASSWORD,
	};
};

/**
 * Methods below are used to determine whether an action has been triggered
 * namely, they set a boolean value for action trigger and the appropriate success/error message
 */

export const loginRequest = (user) => {
	return {
		type: authConstants.LOGIN_REQUEST,
		user,
	};
};

/**
 * Client-scope function, used to set user
 *
 * SUCCESS: Populates 'loggedIn' and 'user' in the authentication state
 *
 * @param {object} user
 */
export const loginSuccess = (user) => {
	return (dispatch) => {
		dispatch({
			type: authConstants.LOGIN_SUCCESS,
			user,
		});
	};
};

/**
 * SET user permissions
 *
 * @param {Array} permissions - user permissions
 * @returns
 */
export const setPermissions = (permissions = null) => {
	return (dispatch) => {
		dispatch({
			type: authConstants.SET_PERMISSIONS,
			data: { permissions },
		});
	};
};

/**
 * Client-scope function, should trigger when login fails
 * SUCCESS:
 *    1. Sets authentication.loggedIn = false
 *    2. Sets authentication.loggingError to errorMessage
 *
 * @param {string} errorMessage - error message
 *
 */
export const loginFailure = (errorMessage) => {
	return {
		type: authConstants.LOGIN_FAILURE,
		error: errorMessage,
	};
};

/**
 * Client-scope function, should trigger when refresh token fails
 * Note: Currently not in use, does not manipulate state
 *
 * @param {string} errorMessage - error message
 *
 */
export const refreshTokenFailure = (error) => {
	return {
		type: authConstants.REFRESH_TOKEN_FAILED,
		error,
	};
};

export const actionSuccess = () => {
	return { type: authConstants.ACTION_SUCCESS };
};

export const actionFailure = (error) => {
	return { type: authConstants.ACTION_FAILURE, error };
};

export const clearAuthRequestState = () => {
	return { type: authConstants.CLEAR_AUTH_REQUEST_STATE };
};
