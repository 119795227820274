import React from "react";
import { Provider } from "react-redux";
import { jwtDecode } from "jwt-decode";
import {
	loginSuccess,
	loginFailure,
	setPermissions,
} from "./actions/authActions";
import { render } from "react-dom";
import App from "./components/App";
import store from "./store";
import "./assets/styles/index.scss";
import {
	getCustomer,
	getItemFromLocalStorage,
	getPermissions,
	getReseller,
	getToken,
} from "./utils/local-storage.util";
import {
	setCustomer,
	updateBimifyProgress,
	updateExtractProgress,
} from "./actions";

//Check if user is logged in on every page reload
const checkLogin = () => {
	if (getToken()) {
		const bimifyProgress = getItemFromLocalStorage("bimifyProgress")
			? JSON.parse(getItemFromLocalStorage("bimifyProgress"))
			: null;

		const extractProgress = getItemFromLocalStorage("extractProgress")
			? JSON.parse(getItemFromLocalStorage("extractProgress"))
			: null;

		store.dispatch(loginSuccess(jwtDecode(getToken())));
		store.dispatch(setCustomer(getCustomer()));
		store.dispatch(setCustomer(getReseller(), true));
		if (!!getPermissions()) store.dispatch(setPermissions(getPermissions()));

		if (!!bimifyProgress) store.dispatch(updateBimifyProgress(bimifyProgress));

		if (!!extractProgress)
			store.dispatch(updateExtractProgress(extractProgress));
	} else {
		store.dispatch(loginFailure("You are not logged in!"));
	}
};

checkLogin();
const root = document.getElementById("root");

render(
	<Provider store={store}>
		<App />
	</Provider>,
	root
);
