import React from "react";
import { renderNumberFormat } from "../../../../utils/number-formatter/NumberFormatter";
import { adornmentTypeConstants } from "../../../../constants/constants";

export const renderNumberModeling = ({
	value,
	toFixed = 2,
	type = adornmentTypeConstants.EURO_METERS,
	typeClassName = "color-n300",
}) => {
	const renderType = () => {
		switch (type) {
			case adornmentTypeConstants.EURO_METERS:
				return (
					<>
						&euro;/m<sup>2</sup>
					</>
				);

			case adornmentTypeConstants.PERCENTAGE:
				return <>%</>;

			case adornmentTypeConstants.EURO:
				return <>&euro;</>;

			case adornmentTypeConstants.HOURS:
				return <>h</>;

			case adornmentTypeConstants.SQUARE_METERS:
				return (
					<>
						m<sup>2</sup>
					</>
				);

			case adornmentTypeConstants.SQUARE_METER_HOUR:
				return (
					<>
						m<sup>2</sup>/h
					</>
				);

			case adornmentTypeConstants.HOUR_SQUARE_METER:
				return (
					<>
						h/m<sup>2</sup>
					</>
				);

			case adornmentTypeConstants.MILIMETERS:
				return <>mm</>;

			default:
				return "";
		}
	};
	return (
		<div className="d-flex align-items-center">
			{!!value ? renderNumberFormat(value, toFixed) : "-"}&nbsp;
			<div className={typeClassName}>{renderType()}</div>
		</div>
	);
};
