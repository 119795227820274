import React from "react";
import { connect } from "react-redux";
import LexicalEditor from "../../../../shared/components/TextEditor/Lexical/LexicalEditor";

const AdditionalInfoEditor = ({
	additionalInfo,
	updateEditorState,
	editView,
	placeholder = "",
	getHTMLData = null,
	wrapperEditorClasses = "",
	scrollerClasses = "max-h-470p",
}) => {
	if (!editView && !additionalInfo) {
		return null;
	}

	return (
		<div className={`w-100`}>
			<LexicalEditor
				placeholder={placeholder}
				getHTMLData={getHTMLData}
				editorState={additionalInfo}
				hideTableAndImage={true}
				onChange={updateEditorState}
				editable={editView}
				wrapperEditorClasses={wrapperEditorClasses}
				scrollerClasses={scrollerClasses}
			/>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { isEdit = false, forJob = false, isForModelUpdate = false } = ownProps;
	let additionalInfo = null;
	if (forJob) {
		const serviceType = state.getIn(["job", "job", "serviceType", "type"]);
		additionalInfo =
			serviceType === "Update"
				? state.getIn(["job", "job", "additionalInfo"])
				: state.getIn(["job", "job", "additionalInfo"]) ||
					state.getIn(["job", "job", "building", "order", "additionalInfo"]) ||
					"";
	} else if (isForModelUpdate) {
		additionalInfo =
			state.getIn(["modelUpdate", "tempModelUpdate", "additionalInfo"]) || "";
	} else {
		additionalInfo = isEdit
			? state.getIn(["projectTemp", "additionalInfo"]) || ""
			: state.getIn(["project", "project", "additionalInfo"]) || "";
	}
	return {
		additionalInfo,
	};
};

export default connect(mapStateToProps, {})(AdditionalInfoEditor);
