import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form/immutable";
import CustomStatusSelect from "../../../components/fields/CustomStatusSelect";
import {
	bimifyConstants,
	statusConstants,
	userRolesConstants,
} from "../../../constants/constants";
import { setFirstLetterUppercase } from "../../../utils/label.util";
import { tokenParams } from "../../../utils/local-storage.util";
import { isBimify, isLoggedUserGuest } from "../../../utils/permission/user";
import { socket } from "../../../utils/web-socket";
import TooltipWithHtml from "../../htmlTooltip/TooltipWithHtml";
import { getStatusIconWrapper } from "./getStatusIcon";

const IconComponent = ({ value, id }) => {
	return (
		<div className="d-flex align-items-center">
			{id && getStatusIconWrapper(id, "mr-s")}
			<div className="">{value}</div>
		</div>
	);
};

const RequestedOfferedStatus = (disabled = false) => [
	{
		value: statusConstants.REQUESTED,
		disabled,
		label: <IconComponent value="Requested" id={statusConstants.REQUESTED} />,
	},
	{
		value: statusConstants.REVIEWED,
		disabled,
		label: <IconComponent value="Reviewed" id={statusConstants.REVIEWED} />,
	},
	{
		value: statusConstants.OFFERED,
		disabled,
		label: <IconComponent value="Offered" id={statusConstants.OFFERED} />,
	},
];

const IncompleteDeclinedArchivedStatus = (
	disabled = false,
	jobDisabled = true
) => [
	{
		value: statusConstants.INCOMPLETE,
		disabled: disabled && jobDisabled,
		label: <IconComponent value="Incomplete" id={statusConstants.INCOMPLETE} />,
	},
	{
		value: statusConstants.DECLINED,
		disabled,
		label: <IconComponent value="Declined" id={statusConstants.DECLINED} />,
	},
	{
		value: statusConstants.ARCHIVED,
		label: <IconComponent value="Archived" id={statusConstants.ARCHIVED} />,
	},
];

export const orderStatus = () => [
	...RequestedOfferedStatus(),
	{
		value: statusConstants.ACCEPTED,
		label: <IconComponent value="Accepted" id={statusConstants.ACCEPTED} />,
	},
	...IncompleteDeclinedArchivedStatus(),
];

export const jobStatus = ({
	disabled = true,
	jobDisabled = false,
	onlyNormalisation = false,
	onlyCustomisation = false,
	onlyQA = false,
	onlyDone = false,
	onlyDelivered = false,
}) => [
	...RequestedOfferedStatus(disabled),
	{
		value: statusConstants.NORMALISATION,
		disabled: onlyNormalisation,
		label: (
			<IconComponent value="Normalisation" id={statusConstants.NORMALISATION} />
		),
	},
	{
		value: statusConstants.CUSTOMISATION,
		disabled: onlyCustomisation,
		label: (
			<IconComponent value="Customisation" id={statusConstants.CUSTOMISATION} />
		),
	},
	{
		value: statusConstants.QA,
		disabled: onlyQA,
		label: <IconComponent value="QA" id={statusConstants.QA} />,
	},
	{
		value: statusConstants.DONE,
		disabled: onlyDone,
		label: <IconComponent value="Done" id={statusConstants.DONE} />,
	},
	{
		value: statusConstants.DELIVERED,
		disabled: onlyDelivered,
		label: <IconComponent value="Delivered" id={statusConstants.DELIVERED} />,
	},
	...IncompleteDeclinedArchivedStatus(disabled, jobDisabled),
];

export const generalStatus = [
	{
		value: statusConstants.ACTIVE,
		label: <IconComponent value="Active" id={statusConstants.ACTIVE} />,
	},
	{
		value: statusConstants.ARCHIVED,
		label: <IconComponent value="Archived" id={statusConstants.ARCHIVED} />,
	},
];

export const ticketStatus = [
	{
		value: statusConstants.RESOLVED,
		label: <IconComponent value="Resolved" id={statusConstants.RESOLVED} />,
	},
	{
		value: statusConstants.PENDING,
		label: <IconComponent value="Pending" id={statusConstants.PENDING} />,
	},
	{
		value: statusConstants.ARCHIVED,
		label: <IconComponent value="Archived" id={statusConstants.ARCHIVED} />,
	},
];

export const getSymbolStatus = (disabled) => [
	{
		value: statusConstants.CATEGORISED,
		//disabled: disabled,
		label: (
			<IconComponent value="Categorised" id={statusConstants.CATEGORISED} />
		),
	},
	{
		value: statusConstants.UNCATEGORISED,
		//disabled: disabled,
		label: (
			<IconComponent value="Uncategorised" id={statusConstants.UNCATEGORISED} />
		),
	},
	{
		value: statusConstants.ARCHIVED,
		label: <IconComponent value="Archived" id={statusConstants.ARCHIVED} />,
	},
];

export const buildingStatus = (disabled = true) => [
	...RequestedOfferedStatus(disabled),
	{
		value: statusConstants.ONGOING,
		label: <IconComponent value="Ongoing" id={statusConstants.ONGOING} />,
	},
	{
		value: statusConstants.COMPLETED,
		label: <IconComponent value="Completed" id={statusConstants.COMPLETED} />,
	},
	...IncompleteDeclinedArchivedStatus(disabled),
];

const StatusComponent = ({ status, ...props }) => {
	const {
		name,
		id,
		isSingleView = false,
		view,
		updateStatusAction,
		canUpdateState = true,
		disabled = !isBimify() || isLoggedUserGuest(),
		loadingDisabled = false,
	} = props;
	const [updatedStatus, setUpdatedStatus] = useState(null);

	const customOnChange = (value) => {
		switch (view) {
			case bimifyConstants.LOD_SPECIFICATION:
				break;

			case bimifyConstants.TICKET:
				updateStatusAction({
					status: true,
					data: { ids: [id], value, name, isSingleView },
				});
				break;

			case bimifyConstants.BUILDING:
				updateStatusAction({
					status: true,
					data: { ids: [id], value, name, isSingleView },
				});
				break;
			case bimifyConstants.JOB:
				updateStatusAction({
					status: true,
					data: { ids: [id], value, isSingleView, name: id },
				});

				break;

			case bimifyConstants.TEMPLATE:
				updateStatusAction({
					status: true,
					data: { ids: [id], value, isSingleView, name: name },
				});

				break;

			case bimifyConstants.CUSTOMER:
			case bimifyConstants.USER:
				updateStatusAction(id);
				break;

			case bimifyConstants.MANUALS:
				updateStatusAction({ status: true, data: { ids: [id], value, name } });
				break;

			case bimifyConstants.COMPONENT_TYPES:
				updateStatusAction({ status: true, data: { ids: [id], value, name } });
				break;

			case bimifyConstants.SYMBOL:
				updateStatusAction({ status: true, data: { ids: [id], value, name } });
				break;
			default:
				updateStatusAction({
					status: true,
					data: { ids: [id], value, isSingleView, name },
				});
				break;
		}
	};

	const returnJobStatus = () => {
		const role = tokenParams().role.type;
		let jobStatusRole = null;

		switch (role) {
			case userRolesConstants.NORMALIZER:
				jobStatusRole = jobStatus({
					onlyCustomisation: true,
					onlyQA: true,
					onlyDone: true,
					onlyDelivered: true,
				});
				jobStatusRole.pop();
				return jobStatusRole;

			case userRolesConstants.CUSTOMIZER:
				jobStatusRole = jobStatus({
					onlyNormalisation: true,
					onlyQA: true,
					onlyDone: true,
					onlyDelivered: true,
				});
				jobStatusRole.pop();
				return jobStatusRole;
			case userRolesConstants.QUALITY_CONTROL:
				jobStatusRole = jobStatus({
					onlyNormalisation: true,
					onlyCustomisation: true,
					onlyDone: true,
					onlyDelivered: true,
				});
				jobStatusRole.pop();
				return jobStatusRole;
			default:
				return jobStatus({});
		}
	};

	const returnItems = () => {
		switch (view) {
			case bimifyConstants.TICKET:
				return ticketStatus;

			case bimifyConstants.JOB:
				return returnJobStatus();

			case bimifyConstants.BUILDING:
				return buildingStatus();

			case bimifyConstants.MANUALS:
			case bimifyConstants.USER:
			case bimifyConstants.CUSTOMER:
			case bimifyConstants.LOD_SPECIFICATION:
			case bimifyConstants.COMPONENT_TYPES:
			case bimifyConstants.TEMPLATE:
				return generalStatus;

			case bimifyConstants.SYMBOL:
				return getSymbolStatus(true);

			default:
				return orderStatus();
		}
	};

	useEffect(() => {
		if (view === bimifyConstants.OFFER || view === bimifyConstants.TICKET) {
			// const socket = getSocket();
			// socket.connect();

			const event =
				view === bimifyConstants.OFFER
					? `order-status-${id}`
					: `ticket-status-${id}`;

			const listener = (status) => {
				setUpdatedStatus(status);
			};
			socket.on(event, listener);
			return () => {
				socket.off(event, listener);
				//	socket.disconnect();
			};
		}
	}, [id, view]);

	return (
		<>
			<form
				className={`form d-flex flex-column flex-auto standardized-form	`}
				autoComplete="off"
				noValidate
			>
				<div className="row row--m-gutters">
					<div className="col col-100">
						<TooltipWithHtml
							tooltipPlacement={"left"}
							tooltipContent={
								updatedStatus
									? statusConstants.QA === updatedStatus
										? updatedStatus.toString().toUpperCase()
										: setFirstLetterUppercase(updatedStatus)
									: statusConstants.QA === status
										? status.toString().toUpperCase()
										: setFirstLetterUppercase(status) || ""
							}
						>
							<Field
								disabled={disabled || loadingDisabled}
								name="status"
								component={CustomStatusSelect}
								customOnChange={customOnChange}
								initialValue={updatedStatus ? updatedStatus : status || ""}
								options={returnItems()}
								canUpdateState={canUpdateState}
							/>
						</TooltipWithHtml>
					</div>
				</div>
			</form>
		</>
	);
};
const mapStateToProps = (state, ownProps) => {
	const { status } = ownProps;

	return {
		initialValues: {
			status,
		},
	};
};
export default connect(
	mapStateToProps,
	{}
)(
	reduxForm({
		form: "statusForm",
		enableReinitialize: true,
		touchOnBlur: false,
	})(StatusComponent)
);
