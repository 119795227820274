import React, { useMemo } from "react";
import FooterLayout from "../../../../../layout/content/FooterLayout";
import CustomStepNavigation from "../../../../../../shared/components/CustomStepper/CustomStepNavigation";
import localize, { LocKeys } from "../../../../../../constants/localizations";
import { useNavigate } from "react-router-dom-v5-compat";
import { connect } from "react-redux";

const ModelUpdateFooter = ({ buildingId, ...props }) => {
	const { tempModelUpdate } = props;
	const navigate = useNavigate();

	const fields = useMemo(
		() => [
			"building",
			"inputFormat",
			"deliveryFormat",
			"specification",
			"template",
		],
		[]
	);

	const isValid = useMemo(() => {
		let hasInvalidValue = Object.keys(tempModelUpdate?.toJS()).some(
			(key) => fields.includes(key) && !tempModelUpdate.get(key)
		);

		return hasInvalidValue;
	}, [tempModelUpdate, fields]);

	return (
		<FooterLayout wrapperClasses="px-24">
			<CustomStepNavigation
				showCancel={true}
				cancelButtonAction={() => navigate(-1)}
				hideLeftButton={true}
				rightButtonDisabled={isValid}
				rightButtonClasses="btn--primary"
				rightButtonLabel={localize(LocKeys.NEXT)}
			/>
		</FooterLayout>
	);
};

const mapStateToProps = (state) => {
	return {
		tempModelUpdate: state.getIn(["modelUpdate", "tempModelUpdate"]),
	};
};

export default connect(mapStateToProps, {})(ModelUpdateFooter);
